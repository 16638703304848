import { Component, OnInit, ViewChild } from '@angular/core';
import { CaptchaComponent } from 'angular-captcha';
import { YourFormWithCaptchaService } from './your-form-with-captcha.service';
// import { Observable } from 'rxjs/Rx';

@Component({
  //moduleId: module.id,
  selector: 'app-your-form-with-captcha',
  templateUrl: './your-form-with-captcha.component.html',
  styleUrls: ['./your-form-with-captcha.component.css'],
  providers: [YourFormWithCaptchaService]
})
export class YourFormWithCaptchaComponent implements OnInit {
  @ViewChild(CaptchaComponent, { static: true }) captchaComponent: CaptchaComponent;

  constructor(private yourFormWithCaptchaService: YourFormWithCaptchaService) { }

  ngOnInit(): void {
    // set the captchaEndpoint property to point to 
    // the captcha endpoint path on your app's backend
    this.captchaComponent.captchaEndpoint = 'http://captcha.emanahmed.com/simple-captcha-endpoint.ashx';
  }
// Process the form on submit event.
validate(value, valid): void {

  // get the user-entered captcha code value to be validated at the backend side        
  let userEnteredCaptchaCode = this.captchaComponent.userEnteredCaptchaCode;

  // get the id of a captcha instance that the user tried to solve
  let captchaId = this.captchaComponent.captchaId;

  const postData = {
    userEnteredCaptchaCode: userEnteredCaptchaCode,
    captchaId: captchaId
  };

  // post the captcha data to the backend
  this.yourFormWithCaptchaService.send(postData)
    .subscribe(
      response => {
        if (response.success == false) {
          // captcha validation failed; reload image
          this.captchaComponent.reloadImage();
          // TODO: maybe display an error message, too
        } else {
          // TODO: captcha validation succeeded; proceed with the workflow
        }
      });
}
}
