import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { YourFormWithCaptchaComponent } from './your-form-with-captcha/your-form-with-captcha.component';


const routes: Routes = [   {
  path: 'test',
  component: YourFormWithCaptchaComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
