import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YourFormWithCaptchaService {
  // ngModel #yourFirstCaptchaUserInput="ngModel"
  constructor(private http: HttpClient) { }
  send(data: Object): Observable<any> {
    const options = {   headers: new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'}) };
    return this.http.post( 'http://captcha.emanahmed.com/api/YourForm/TestData',  data, options);
  }
}
